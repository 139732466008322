<script setup>
import { Pagination, Navigation, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/a11y'

const modules = [Navigation, A11y, Pagination]

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const pickedItems = await useAsyncData(`BlockSlider-${props.data.id}`, () =>
  props.data.items
    .filter((item) =>
      checkDateRange(item.date_published, item.date_unpublished)
    )
    .pickItemsAndRandomize(props.data.pick_items, props.data.randomize)
)
</script>

<template>
  <div
    v-if="data?.items && pickedItems.data.value.length > 0"
    :class="['slider', 'swiper-' + data.type]"
    :style="{ '--aspect-ratio': data.aspect_ratio || '3/1' }"
  >
    <template v-if="pickedItems.data.value.length > 1 && !data.row_mode">
      <nuxt-error-boundary @error="() => {}">
        <Swiper
          :modules="modules"
          :navigation="true"
          :pagination="{
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 4,
          }"
          :slides-per-view="
            data.type === 'small'
              ? Math.min(2, pickedItems.data.value.length)
              : 1
          "
          :breakpoints="{
            '768': {
              slidesPerView:
                data.type === 'small'
                  ? Math.min(3, pickedItems.data.value.length)
                  : 1,
            },
          }"
          :space-between="data.type === 'small' ? 20 : 0"
          :loop="true"
          :grab-cursor="true"
          :speed="500"
        >
          <SwiperSlide v-for="item of pickedItems.data.value" :key="item.id">
            <BlockSliderItem :item="item" :data="data" />
          </SwiperSlide>
        </Swiper>
      </nuxt-error-boundary>
    </template>
    <template v-else>
      <div
        v-for="item of pickedItems.data.value"
        :key="item.id"
        class="slider-row"
      >
        <BlockSliderItem :item="item" :data="data" />
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.slider {
  overflow: hidden;
  position: relative;
  line-height: 1.2;
  margin-bottom: 2rem !important;
  --swiper-theme-color: white;
  --swiper-pagination-custom-height: 20px; // OUR VAR
  --swiper-pagination-color: var(--color-text-soft);
  --swiper-pagination-bullet-inactive-color: var(--color-text-soft);
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-size: 9px;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bottom: 1px;
  --swiper-navigation-size: 1.5em;
  --swiper-navigation-sides-offset: 2px;
  --swiper-navigation-top-offset: calc(
    50% - var(--swiper-pagination-custom-height)
  );
  :deep() {
    .swiper {
      padding-bottom: var(--swiper-pagination-custom-height) !important;
    }
    .swiper-button-prev,
    .swiper-button-next {
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
      padding: 1em;
    }
    .swiper-slide {
      overflow: hidden;
    }
  }
}
.slider-row + .slider-row {
  margin-top: 2em;
}
.swiper-wrapper {
  aspect-ratio: var(--aspect-ratio, 3/1);
  box-sizing: border-box;
  position: relative;
  .swiper {
    height: 100%;
  }
}
:deep(.swiper-slide) {
  // aspect-ratio: var(--aspect-ratio, 3/1);
  height: auto;
  :focus-visible {
    @extend %focus-visual;
    outline-width: 4px;
    outline-offset: -4px;
  }
}

// Variations for the different types of sliders (full-width, standard, small)

.swiper-standard {
  padding: 0 var(--layout-margin-h);
  max-width: var(--layout-max-width);
  margin: 0 auto;
  :deep(.swiper-slide-image-text) {
    .swiper-item {
      display: flex;
      flex-direction: column;
    }
    .swiper-text {
      // Text next to Image
      padding: 1em;
      box-sizing: border-box;
      gap: 0.2rem;
      flex: 1;
      .swiper-heading {
        @extend %font-hl2;
        hyphens: manual;
        padding: 0;
        &.swiper-heading-long {
          @extend %font-hl3;
        }
      }
      .swiper-summary {
        @extend %font-copy;
        padding: 0.5em 0;
      }
      @include media('>=tablet') {
        order: -1;
        flex: unset;
      }
      @include media('>=desktop') {
        gap: 0.3rem;
        padding: 1.5em 2em;
      }
    }
    .swiper-image {
      aspect-ratio: var(--aspect-ratio, 3/1);
      width: 100%;
    }
    @include media('>=tablet') {
      aspect-ratio: var(--aspect-ratio, 3/1);
      .swiper-text {
        width: 30em;
      }
      .swiper-image {
        aspect-ratio: unset;
        width: calc(100% - 30em);
      }
      .swiper-item {
        flex-direction: row;
      }
    }
  }
  :deep(.swiper-slide-text) {
    @include media('>=tablet') {
      aspect-ratio: var(--aspect-ratio, 3/1);
    }
    .swiper-text {
      height: 100%;
      justify-content: space-between;
    }
  }
}
.swiper-full-width {
  margin: 0 auto;
  :deep(.swiper-wrapper),
  .swiper-item,
  :deep(.swiper-slide) {
    max-height: 70vh;
  }
  :deep(.swiper-item:after) {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    aspect-ratio: var(--aspect-ratio, 3/1);
  }
  :deep(.swiper-text) {
    // Big Text over Image
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 2em;
    z-index: 1;
  }
  :deep(.swiper-image) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @include media('<phone') {
    --aspect-ratio: 5/3 !important;
  }
}

.swiper-small {
  --swiper-pagination-bottom: -0.2em;
  --swiper-pagination-color: var(--color-text);
  padding: 0 var(--layout-margin-h);
  max-width: var(--layout-max-width-sm);
  margin: 0 auto;
  aspect-ratio: initial;
  :deep(.swiper-item) {
    padding-bottom: 1rem;
  }
  :deep(.swiper-text) {
    padding: 1em 0;
    .swiper-heading,
    .swiper-summary,
    .swiper-caption {
      @extend %font-caption;
      padding: 0;
    }
  }
  :deep(.swiper-image) {
    height: auto;
    aspect-ratio: var(--aspect-ratio, 3/2);
  }
}
</style>
